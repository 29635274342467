import * as authActions from "../../../store/actions/authAction";
import * as adminPersonActions from "../../../store/admin/actions/adminPersonActions";
import * as adminRelationshipActions from "../../../store/admin/actions/adminRelationshipActions";
import * as adminRelationshipTypeActions from "../../../store/admin/actions/adminRelationshipTypeActions";
import { dismisAlert, generateAlert } from "../../../utils/alertUtils";
import { filterArray } from "../../../utils/filterUtils";
import { contentTranslator } from "../../../utils/translatorUtils";
import { SkillsTxTable } from "../../common/SkillsTxTable";
import { SkillsTxTableSearchBar } from "../../common/SkillsTxTableSearchBar";
import RelationshipBulkUpload from "./RelationshipBulkUpload";
import { faArrowLeft, faArrowRight, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
// import filterFactory from "react-bootstrap-table2-filter";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// import BootstrapTable from "react-bootstrap-table-next";
import { AlertList } from "react-bs-notifier";
import { connect } from "react-redux";
import { Button, ButtonGroup, Col, Label, Row, Spinner } from "reactstrap";
import { bindActionCreators } from "redux";

const alertAutoHideInterval = process.env.REACT_APP_ALERT_AUTO_HIDE_INTERVAL;

const headerStyle = {
    color: "#2B2B2B",
    fontSize: "18px",
    fontFamily: "IBMPlexSans-Bold",
};

const rowStyle = {
    background: "#FFFFFF",
    fontSize: "12px",
    cursor: "pointer",
};

// const { SearchBar } = Search;
const FileDownload = require("js-file-download");
class ManageRelationships extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            loading: true,
            alerts: [],
            relationshipTypes: [],
            persons: [],
            isUpdating: false,
            mode: "DownToTop",
            personsSelected: [],
            availablePersons: [],
            personsAvailableSelected: [],
            assessedPersons: [],
            personsAssessedSelected: [],
            showDialogImportTs: null,
            searchProperties: ["email", "firstName", "lastName"],
        };

        this.filterArray = filterArray.bind(this);
        this.generateAlert = generateAlert.bind(this);
        this.dismisAlert = dismisAlert.bind(this);
        this.contentTranslator = contentTranslator.bind(this);
    }

    componentDidMount() {
        this.props.onAuth.authCheckState().then(() => {
            this.props.onRelationshipType.getRelationshipTypes().then(() => {
                this.initData();
                this.setState({
                    loading: this.props.loading || this.props.relationshipTypeLoading,
                    relationshipTypes: this.props.relationshipTypes.filter((it) => it.name != "Endorser"),
                });
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.persons !== this.props.persons) {
            this.setState({
                persons: this.props.persons ? this.props.persons.filter((it) => !it.inactive && !it.isCandidate).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)) : [],
            });
        }
    }

    initData = () => {
        const personsSelected = this.state.personsSelected;
        let personSelectedId = null;

        if (personsSelected && personsSelected.length > 0) {
            personSelectedId = personsSelected[0];
        }

        this.props.onPerson.getPersonsIncRelationships().then(() => {
            const persons = this.props.persons ? this.props.persons.filter((it) => !it.inactive && !it.isCandidate).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)) : [];

            if (personSelectedId) {
                if (!persons.find((it) => it.id == personSelectedId)) {
                    personSelectedId = persons[0].id;
                    persons[0].toggleSelected = true;
                } else {
                    const person = persons.find((it) => it.id == personSelectedId);
                    person.toggleSelected = true;
                }
            } else {
                personSelectedId = persons[0].id;
                persons[0].toggleSelected = true;
            }

            this.setState(
                {
                    persons: persons,
                    personsSelected: [personSelectedId],
                },
                () => {
                    this.handleSelectRow(personSelectedId);
                }
            );
        });
    };

    handleRefresh = () => {
        this.initData();
    };

    handleOnSelect = (row) => {
        if (this.state.isUpdating) {
            return false;
        }

        const persons = this.state.persons;
        const updatedData = persons.find((it) => it.id == row.id);

        if (!updatedData.toggleSelected) {
            persons.forEach((it) => {
                it.toggleSelected = false;
            });

            updatedData.toggleSelected = true;

            this.handleSelectRow(row.id);
            this.setState({ persons: [...persons], personsSelected: [row.id] });
        }
    };

    handleAfterSearch = (searchKeyword) => {
        const { persons, searchProperties } = this.state;
        const filtered = this.filterArray(persons, searchKeyword, searchProperties);

        persons.forEach((it) => {
            it.toggleSelected = false;
        });

        if (filtered.length > 0) {
            const row = filtered[0];
            const person = persons.find((it) => it.id == row.id);
            person.toggleSelected = true;
            this.handleSelectRow(person.id);
            this.setState({ persons: [...persons], personsSelected: [person.id] });
        } else {
            this.handleSelectRow();
            this.setState({ persons: [...persons], personsSelected: [] });
        }
    };

    handleSelectRow = (personId) => {
        const persons = this.props.persons;
        const { mode, personsSelected, personsAvailableSelected, personsAssessedSelected } = this.state;

        var personSelected = null;

        if (personsSelected.length > 0) {
            personSelected = personsSelected[0];
        }

        let relationships = [];
        let availablePersons = [];
        let assessedPersons = [];

        const selectedPerson = persons.find((it) => it.id == personId);

        if (selectedPerson) {
            if (mode == "DownToTop") {
                relationships = selectedPerson.downRelationships;
                let relationshipsId = [];
                if (relationships && relationships.length > 0) {
                    relationshipsId = relationships.map((it) => {
                        return it.personAId;
                    });
                }

                availablePersons = [...persons.filter((it) => !it.inactive && !it.isCandidate && it.id != personId && !relationshipsId.includes(it.id)).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))];
                assessedPersons = [
                    ...persons
                        .filter((it) => !it.inactive && !it.isCandidate && it.id != personId && relationshipsId.includes(it.id))
                        .sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))
                        .map((it) => {
                            return {
                                id: relationships.find((r) => r.personAId == it.id).id,
                                personId: it.id,
                                firstName: it.firstName,
                                lastName: it.lastName,
                                email: it.email,
                                relationshipType: relationships.find((r) => r.personAId == it.id).relationshipType,
                                status: relationships.find((r) => r.personAId == it.id).status,
                            };
                        })
                        .sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))
                        .sort((a, b) => (`${a.relationshipType == "Team Leader" ? 1 : a.relationshipType == "Line Manager" ? 2 : 3}` > `${b.relationshipType == "Team Leader" ? 1 : b.relationshipType == "Line Manager" ? 2 : 3}` ? 1 : -1)),
                ];
            } else {
                relationships = selectedPerson.upRelationships;
                const relationshipsId = relationships.map((it) => {
                    return it.personBId;
                });

                availablePersons = [...persons.filter((it) => !it.inactive && !it.isCandidate && it.id != personId && !relationshipsId.includes(it.id)).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))];
                assessedPersons = [
                    ...persons
                        .filter((it) => !it.inactive && !it.isCandidate && it.id != personId && relationshipsId.includes(it.id))
                        .sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))
                        .map((it) => {
                            return {
                                id: relationships.find((r) => r.personBId == it.id).id,
                                personId: it.id,
                                firstName: it.firstName,
                                lastName: it.lastName,
                                email: it.email,
                                relationshipType: relationships.find((r) => r.personBId == it.id).relationshipType,
                                status: relationships.find((r) => r.personBId == it.id).status,
                            };
                        })
                        .sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1))
                        .sort((a, b) => (`${a.relationshipType == "Team Leader" ? 1 : a.relationshipType == "Line Manager" ? 2 : 3}` > `${b.relationshipType == "Team Leader" ? 1 : b.relationshipType == "Line Manager" ? 2 : 3}` ? 1 : -1)),
                ];
            }

            this.setState({
                availablePersons: JSON.parse(JSON.stringify(availablePersons)),
                personsAvailableSelected: personSelected != personId ? [] : [...personsAvailableSelected],
                personsSelected: personId ? [personId] : [],
                assessedPersons: JSON.parse(JSON.stringify(assessedPersons)),
                personsAssessedSelected: personSelected != personId ? [] : [...personsAssessedSelected],
            });
        }
    };

    handleAfterSearchAvailable = () => {};

    handleOnSelectAvailable = (row) => {
        if (this.state.isUpdating) {
            return false;
        }

        const { availablePersons } = this.state;
        let personsAvailableSelected = this.state.personsAvailableSelected;

        if (personsAvailableSelected) {
            const availablePerson = availablePersons.find((it) => it.id == row.id);
            if (personsAvailableSelected.find((it) => it == row.id)) {
                availablePerson.toggleSelected = false;

                const personAvailableSelected = personsAvailableSelected.find((it) => it == row.id);
                var index = personsAvailableSelected.indexOf(personAvailableSelected);
                if (index !== -1) {
                    personsAvailableSelected.splice(index, 1);
                }
            } else {
                availablePerson.toggleSelected = true;
                personsAvailableSelected = [...personsAvailableSelected, row.id];
            }
        } else {
            const availablePerson = availablePersons.find((it) => it.id == row.id);
            availablePerson.toggleSelected = true;
            personsAvailableSelected = [row.id];
        }

        this.setState({ availablePersons: [...availablePersons], personsAvailableSelected: [...personsAvailableSelected] });
    };

    handleOnSelectAssessed = (row) => {
        if (this.state.isUpdating) {
            return false;
        }

        const { assessedPersons } = this.state;
        let personsAssessedSelected = this.state.personsAssessedSelected;

        if (personsAssessedSelected) {
            const assessedPerson = assessedPersons.find((it) => it.id == row.id);
            if (personsAssessedSelected.find((it) => it == row.id)) {
                assessedPerson.toggleSelected = false;

                const personAssessedSelected = personsAssessedSelected.find((it) => it == row.id);
                var index = personsAssessedSelected.indexOf(personAssessedSelected);
                if (index !== -1) {
                    personsAssessedSelected.splice(index, 1);
                }
            } else {
                assessedPerson.toggleSelected = true;
                personsAssessedSelected = [...personsAssessedSelected, row.id];
            }
        } else {
            const assessedPerson = assessedPersons.find((it) => it.id == row.id);
            assessedPerson.toggleSelected = true;
            personsAssessedSelected = [row.id];
        }

        this.setState({ assessedPersons: [...assessedPersons], personsAssessedSelected: [...personsAssessedSelected] });
    };

    changeMode = (mode) => {
        this.setState({ mode });
        this.initData();
    };

    handleAssignRelationship = (relationshipType) => {
        const persons = this.props.persons;
        const { mode, personsSelected, personsAvailableSelected } = this.state;
        let personSelectedId = null;

        if (personsSelected && personsSelected.length > 0) {
            personSelectedId = personsSelected[0];
        }

        const selectedPerson = persons.find((it) => it.id == personSelectedId);

        if (selectedPerson) {
            this.setState({ isUpdating: true });
            const relationships = personsAvailableSelected.map((it) => {
                return {
                    relationshipTypeId: relationshipType.id,
                    relationshipType: relationshipType.name,
                    personAId: mode == "DownToTop" ? it : selectedPerson.id,
                    personBId: mode == "DownToTop" ? selectedPerson.id : it,
                };
            });

            this.props.onRelationship.addRelationships(relationships).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.personsError) {
                        this.setState(
                            {
                                persons: this.props.persons ? this.props.persons.filter((it) => !it.inactive && !it.isCandidate).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)) : [],
                                personsAvailableSelected: [],
                            },
                            () => {
                                this.handleSelectRow(selectedPerson.id);
                            }
                        );

                        this.generateAlert("success", "Relationships assigned.");
                    } else {
                        this.generateAlert("danger", this.props.personsError.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    handleRemoveRelationshipsClick = () => {
        const persons = this.props.persons;
        const { personsSelected, personsAssessedSelected } = this.state;

        let personSelectedId = null;

        if (personsSelected && personsSelected.length > 0) {
            personSelectedId = personsSelected[0];
        }

        const selectedPerson = persons.find((it) => it.id == personSelectedId);

        if (selectedPerson) {
            this.setState({ isUpdating: true });
            this.props.onRelationship.deleteRelationships(personsAssessedSelected).then(() => {
                if (!this.props.isUpdating) {
                    if (!this.props.personsError) {
                        this.setState(
                            {
                                persons: this.props.persons ? this.props.persons.filter((it) => !it.inactive && !it.isCandidate).sort((a, b) => (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1)) : [],
                                personsAvailableSelected: [],
                            },
                            () => {
                                this.handleSelectRow(selectedPerson.id);
                            }
                        );

                        this.generateAlert("success", "Relationships removed.");
                    } else {
                        this.generateAlert("danger", this.props.personsError.errData.Message);
                    }
                }

                this.setState({ isUpdating: this.props.isUpdating });
            });
        }
    };

    pad2 = (n) => {
        return (n < 10 ? "0" : "") + n;
    };

    handleExportRelationships = () => {
        this.props.onRelationship.exportRelationshipsDataExcel().then(() => {
            if (!this.props.loadingExport) {
                if (this.props.exportData) {
                    var today = new Date();
                    var strToday = today.getFullYear() + this.pad2(today.getMonth() + 1) + this.pad2(today.getDate()) + "_" + this.pad2(today.getHours()) + this.pad2(today.getMinutes()) + this.pad2(today.getSeconds());
                    FileDownload(this.props.exportData, `ExportRelationships_${strToday}.xlsx`);
                }
            }
        });
    };

    handleImportRelationships = () => {
        this.setState({ showDialogImportTs: new Date() });
    };

    handleImportSuccess = () => {
        this.initData();
    };

    render() {
        const { mode, relationshipTypes } = this.state;

        const pageAssignments = this.props.pageAssignments;
        const pageAssignment = pageAssignments.find((it) => it.pageName == "Manage Relationships");

        const handleOnSelect = this.handleOnSelect;
        const handleOnSelectAvailable = this.handleOnSelectAvailable;
        const handleOnSelectAssessed = this.handleOnSelectAssessed;

        const columns = [
            {
                id: "firstName",
                name: "First",
                selector: (row) => row.firstName,
                sortable: false,
                width: "100%",
                style: { cursor: "pointer" },
                format: function (row, index) {
                    return (
                        <div style={{ padding: "10px 5px", cursor: "pointer" }} onClick={(e) => handleOnSelect(row)}>
                            <span style={{ fontSize: "18px" }}>{`${row.firstName} ${row.lastName}`}</span>
                            <br />
                            {mode != "DownToTop" && row.position && (
                                <div style={{ color: "#007bff" }}>
                                    {row.position}
                                    <br />
                                </div>
                            )}
                            {row.email}
                        </div>
                    );
                },
            },
        ];

        const columnsAvailable = [
            {
                id: "firstName",
                name: "First",
                selector: (row) => row.firstName,
                sortable: false,
                width: "100%",
                style: { cursor: "pointer" },
                format: function (row, index) {
                    return (
                        <div style={{ padding: "10px 5px", cursor: "pointer" }} onClick={(e) => handleOnSelectAvailable(row)}>
                            <span style={{ fontSize: "18px" }}>{`${row.firstName} ${row.lastName}`}</span>
                            <br />
                            {mode == "DownToTop" && row.position && (
                                <div style={{ color: "#007bff" }}>
                                    {row.position}
                                    <br />
                                </div>
                            )}
                            {row.email}
                        </div>
                    );
                },
            },
        ];

        const columnsAssessed = [
            {
                id: "firstName",
                name: "First",
                selector: (row) => row.firstName,
                sortable: false,
                width: "100%",
                style: { cursor: "pointer" },
                format: function (row, index) {
                    return (
                        <div style={{ padding: "10px 5px", cursor: "pointer" }} onClick={(e) => handleOnSelectAssessed(row)}>
                            <span style={{ fontSize: "18px" }}>{`${row.firstName} ${row.lastName}`}</span>
                            <br />
                            {row.email}
                            <br />
                            Relationship Type : <span style={{ color: "#007bff" }}>{row.relationshipType}</span>
                            {row.relationshipType == "Mentor" ? (
                                <React.Fragment>
                                    <br />
                                    Mentor Status : <span style={{ color: "#007bff" }}>{row.status ? row.status : "Requested"}</span>
                                </React.Fragment>
                            ) : (
                                ""
                            )}
                        </div>
                    );
                },
            },
        ];

        return (
            <React.Fragment>
                <AlertList className={"alert-fixed"} position={"top-right"} alerts={this.state.alerts} timeout={alertAutoHideInterval * 1} dismissTitle="Close!" onDismiss={this.dismisAlert} />
                <RelationshipBulkUpload handleImportSuccess={this.handleImportSuccess} showDialog={this.state.showDialogImportTs} generateAlert={this.generateAlert}></RelationshipBulkUpload>
                {this.state.loading ? (
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Spinner size="lg" animation="border" role="status"></Spinner>
                    </div>
                ) : (
                    <React.Fragment>
                        <Row>
                            <Label sm={2}>Mode :</Label>
                            <Col sm="10">
                                <ButtonGroup>
                                    <Button color="primary" outline={this.state.mode == "DownToTop" ? false : true} onClick={() => this.changeMode("DownToTop")} disabled={this.state.isUpdating}>
                                        Employee focus
                                    </Button>
                                    <Button color="primary" outline={this.state.mode != "DownToTop" ? false : true} onClick={() => this.changeMode("TopToDown")} disabled={this.state.isUpdating}>
                                        Leadership focus
                                    </Button>
                                </ButtonGroup>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Export" && op.allow == true) && (
                                    <React.Fragment>
                                        <Button
                                            color="primary"
                                            onClick={this.handleExportRelationships}
                                            style={{
                                                fontSize: "16px",
                                                height: "40px",
                                            }}
                                            disabled={this.props.loadingExport}
                                        >
                                            <FontAwesomeIcon icon={faFileExcel} /> Export
                                        </Button>
                                        &nbsp;&nbsp;
                                    </React.Fragment>
                                )}
                                {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Import" && op.allow == true) && (
                                    <Button
                                        color="primary"
                                        onClick={this.handleImportRelationships}
                                        style={{
                                            fontSize: "16px",
                                            height: "40px",
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFileExcel} /> Import
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md="4">
                                <p className={"page-title"} style={headerStyle}>
                                    {this.state.mode == "DownToTop" ? "Employee Name" : "Leadership Name"}
                                </p>
                                <div>
                                    <Row style={{ marginBottom: "10px" }}>
                                        <Col md="12">
                                            <SkillsTxTableSearchBar
                                                searchKeyword={this.state.searchKeywordLevel1}
                                                onChange={(searchKeywordLevel1) => {
                                                    this.setState({ searchKeywordLevel1 });
                                                    this.handleAfterSearch(searchKeywordLevel1);
                                                }}
                                            ></SkillsTxTableSearchBar>
                                        </Col>
                                    </Row>
                                    <div style={{ maxHeight: "500px", minHeight: "500px", overflowY: "scroll" }}>
                                        <SkillsTxTable
                                            columns={columns}
                                            data={this.state.persons}
                                            pagination={false}
                                            noTableHead={true}
                                            searchKeyword={this.state.searchKeywordLevel1}
                                            searchProperties={this.state.searchProperties}
                                            selectableRows={true}
                                            selectableRowsHighlight={true}
                                            handleRowClick={this.handleOnSelect}
                                            progressPending={this.props.loading}
                                        ></SkillsTxTable>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4">
                                <p className={"page-title"} style={headerStyle}>
                                    Select from list :
                                </p>
                                <div>
                                    <Row style={{ marginBottom: "10px" }}>
                                        <Col md="12">
                                            <SkillsTxTableSearchBar
                                                searchKeyword={this.state.searchKeywordLevel2}
                                                onChange={(searchKeywordLevel2) => {
                                                    this.setState({ searchKeywordLevel2 });
                                                }}
                                            ></SkillsTxTableSearchBar>
                                        </Col>
                                    </Row>
                                    <div style={{ maxHeight: "500px", minHeight: "500px", overflowY: "scroll" }}>
                                        <SkillsTxTable
                                            columns={columnsAvailable}
                                            data={this.state.availablePersons}
                                            pagination={false}
                                            noTableHead={true}
                                            searchKeyword={this.state.searchKeywordLevel2}
                                            searchProperties={this.state.searchProperties}
                                            selectableRows={true}
                                            selectableRowsHighlight={true}
                                            handleRowClick={this.handleOnSelectAvailable}
                                            progressPending={this.props.loading}
                                        ></SkillsTxTable>
                                    </div>
                                </div>
                                <br />
                                {relationshipTypes.map((it, key) => {
                                    const allow = pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == `Assign ${it.name}` && op.allow == true) ? true : false;
                                    if (allow) {
                                        return (
                                            <Button
                                                key={key}
                                                color="primary"
                                                onClick={() => this.handleAssignRelationship(it)}
                                                style={{
                                                    width: "100%",
                                                    marginBottom: "10px",
                                                }}
                                                disabled={!(this.state.personsAvailableSelected && this.state.personsAvailableSelected.length > 0) || this.state.isUpdating}
                                            >
                                                <Row>
                                                    <Col md="10">{`Assign ${this.state.mode == "DownToTop" ? "AS" : "TO"} ${it.name}`}</Col>
                                                    <Col md="2">
                                                        <FontAwesomeIcon icon={faArrowRight} />
                                                    </Col>
                                                </Row>
                                            </Button>
                                        );
                                    } else {
                                        return "";
                                    }
                                })}
                            </Col>
                            <Col md="4">
                                <p className={"page-title"} style={headerStyle}>
                                    {this.state.mode == "DownToTop" ? "Leadership Name" : "Employee Name"}
                                </p>
                                <div>
                                    <Row style={{ marginBottom: "10px" }}>
                                        <Col md="12">
                                            <SkillsTxTableSearchBar
                                                searchKeyword={this.state.searchKeywordLevel3}
                                                onChange={(searchKeywordLevel3) => {
                                                    this.setState({ searchKeywordLevel3 });
                                                }}
                                            ></SkillsTxTableSearchBar>
                                        </Col>
                                    </Row>
                                    <div style={{ maxHeight: "500px", minHeight: "500px", overflowY: "scroll" }}>
                                        <SkillsTxTable
                                            columns={columnsAssessed}
                                            data={this.state.assessedPersons}
                                            pagination={false}
                                            noTableHead={true}
                                            searchKeyword={this.state.searchKeywordLevel3}
                                            searchProperties={this.state.searchProperties}
                                            selectableRows={true}
                                            selectableRowsHighlight={true}
                                            handleRowClick={this.handleOnSelectAssessed}
                                            progressPending={this.props.loading}
                                        ></SkillsTxTable>
                                    </div>
                                </div>
                                <br />
                                {pageAssignment && pageAssignment.operations && pageAssignment.operations.find((op) => op.operation == "Remove Relationship" && op.allow == true) && (
                                    <Button
                                        color="warning"
                                        onClick={this.handleRemoveRelationshipsClick}
                                        style={{
                                            width: "100%",
                                            marginBottom: "10px",
                                        }}
                                        disabled={!(this.state.personsAssessedSelected && this.state.personsAssessedSelected.length > 0) || this.state.isUpdating}
                                    >
                                        <Row>
                                            <Col md="2">
                                                <FontAwesomeIcon icon={faArrowLeft} />
                                            </Col>
                                            <Col md="10">Remove Relationship</Col>
                                        </Row>
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,

        personsLoading: state.adminPerson.loading,
        personsError: state.adminPerson.error,
        persons: state.adminPerson.persons,
        isUpdating: state.adminPerson.isUpdating,

        relationshipTypeLoading: state.adminRelationshipType.loading,
        relationshipTypes: state.adminRelationshipType.relationshipTypes,

        exportData: state.adminRelationship.exportData,
        loadingExport: state.adminRelationship.loadingExport,

        pageAssignments: state.adminPerson.pageAssignments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: bindActionCreators(authActions, dispatch),
        onPerson: bindActionCreators(adminPersonActions, dispatch),
        onRelationshipType: bindActionCreators(adminRelationshipTypeActions, dispatch),
        onRelationship: bindActionCreators(adminRelationshipActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageRelationships);
